p,
h1,
h2,
h3,
h4,
li {
    padding: auto;
    margin-bottom: 12px;
}

ol {
    list-style: decimal;
}

ul {
    list-style: circle;
}

ol,
ul {
    padding-left: 4ex;
}

h1,
h2,
h3,
h4 {
    color: #fff;
    font-weight: 600;
}

p,
li {
    color: #a9b5cb;
}
